import { render, staticRenderFns } from "./ClubCta.vue?vue&type=template&id=0639eff9&"
import script from "./ClubCta.ts?vue&type=script&lang=ts&"
export * from "./ClubCta.ts?vue&type=script&lang=ts&"
import style0 from "./ClubCta.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InlineSvg: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/InlineSvg/InlineSvg.vue').default,Ico: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
